var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inline-flex items-baseline space-x-1" }, [
    _c("p", { staticClass: "text-xl font-semibold" }, [
      _vm._v(" " + _vm._s(_vm.data.hours) + " "),
      _c("span", { staticClass: "text-base" }, [_vm._v("hr")]),
    ]),
    _c("p", { staticClass: "text-xl font-semibold" }, [
      _vm._v(" " + _vm._s(_vm.data.minutes) + " "),
      _c("span", { staticClass: "text-base" }, [_vm._v("min")]),
    ]),
    _c("span", { staticClass: "block font-normal text-gray-800" }, [
      _vm._v(" " + _vm._s(_vm.formattedTitle) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }