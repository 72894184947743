<template>
  <div class="inline-flex items-baseline space-x-1">
    <p class="text-xl font-semibold">
      {{ data.hours }}
      <span class="text-base">hr</span>
    </p>
    <p class="text-xl font-semibold">
      {{ data.minutes }}
      <span class="text-base">min</span>
    </p>
    <span class="block font-normal text-gray-800">
      {{ formattedTitle }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedTitle() {
      return this.title
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase();
    },
  },
};
</script>
