<template>
  <narrow-layout class="min-h-screen">
    <spinner-overlay :loading="loading" color="transparent" />
    <div class="relative flex flex-col">
      <h1 class="mb-6 font-display text-4xl">
        Statistics from {{ fromDate }} to {{ toDate }}
      </h1>
      <div class="flex w-1/2 space-x-4">
        <date-range-picker
          ref="picker"
          v-model="dateRange"
          opens="right"
          :locale-data="{ firstDay: 1, format: 'YYYY-MM-DD' }"
          :max-date="new Date()"
          single-date-picker="range"
          :show-week-numbers="true"
          :show-dropdowns="true"
          :ranges="customRanges"
          :auto-apply="true"
          @update="submit()"
        >
          <template v-slot:input="picker" style="min-width: 350px">
            {{ format(new Date(picker.startDate), 'yyyy-MM-dd') }} -
            {{ format(new Date(picker.endDate), 'yyyy-MM-dd') }}
          </template>
        </date-range-picker>
      </div>
      <div
        v-if="overThreeMonths"
        class="mb-3 mt-2 flex w-1/3 items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900"
      >
        <span><fv-icon icon="warning" /></span>
        <span>Select a date range less than three months</span>
      </div>
    </div>
    <!-- Content -->
    <div
      class="flex flex-col space-y-4 py-5"
      :class="{ 'blur-sm filter': loading }"
    >
      <div v-if="!userIsGerman" class="flex md:pr-4">
        <card class="w-full md:w-1/2">
          <p
            class="mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide"
          >
            Work day
          </p>
          <div class="flex flex-col space-y-6 py-2">
            <div class="flex flex-col space-y-2">
              {{ workDayStart }} - {{ workDayEnd }}
            </div>
          </div>
        </card>
      </div>

      <div class="flex space-x-4">
        <card v-if="!userIsGerman" class="flex-1">
          <p
            class="mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide"
          >
            Worked hours
          </p>
          <div class="flex flex-col space-y-6 py-2">
            <div class="flex flex-col space-y-2">
              <statistics-time-row
                v-for="(item, index) in hourData"
                :key="index"
                :data="item"
                :title="index === 'inHour' ? 'Weekday' : index"
              >
              </statistics-time-row>
            </div>
          </div>
        </card>

        <card class="flex-1">
          <p
            class="mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide"
          >
            By the numbers
          </p>

          <div class="flex flex-col space-y-6 py-2">
            <div class="flex flex-col space-y-2">
              <div class="inline-flex items-baseline space-x-1">
                <p class="text-xl font-semibold">
                  {{ slotData.averageRating }}
                </p>
                <span class="block font-normal text-gray-800">
                  in average rating
                </span>
              </div>
              <div class="inline-flex items-baseline space-x-1">
                <p class="text-xl font-semibold">
                  {{ slotData.finishedJournals }}/{{
                    slotData.appointmentCount
                  }}
                </p>
                <span class="block font-normal text-gray-800">
                  finished journals
                </span>
              </div>
              <div class="inline-flex items-baseline space-x-1">
                <p class="text-xl font-semibold">
                  {{ slotData.appointmentCount }}
                </p>
                <span class="block font-normal text-gray-800">
                  total consultations
                </span>
              </div>
            </div>
          </div>
        </card>
      </div>

      <div class="flex space-x-4">
        <card class="flex-1">
          <p
            class="mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide"
          >
            Consultations (other services)
          </p>
          <div class="flex flex-col space-y-6 py-2">
            <div class="flex flex-col space-y-2">
              <statistics-slot-row
                :data="slotData.ordinaryAppointment"
                title="weekday"
              />
              <statistics-slot-row
                :data="slotData.overtimeAppointment"
                title="outside business hours"
              />
              <statistics-slot-row
                :data="
                  slotData.ordinaryAppointment + slotData.overtimeAppointment
                "
                title="total"
              />
            </div>
          </div>
        </card>

        <card class="flex-1">
          <p
            class="mb-2 flex items-center border-b pb-2 font-display text-2xl font-black tracking-wide"
          >
            Consultations (prescription service)
          </p>

          <div class="flex flex-col space-y-6 py-2">
            <div class="flex flex-col space-y-2">
              <statistics-slot-row
                :data="slotData.ordinaryPrescription"
                title="weekday"
              />
              <statistics-slot-row
                :data="slotData.overtimePrescription"
                title="outside business hours"
              />
              <statistics-slot-row
                :data="
                  slotData.ordinaryPrescription + slotData.overtimePrescription
                "
                title="total"
              />
            </div>
          </div>
        </card>
      </div>

      <div class="flex-1">
        <card v-show="slotData.appointmentCount">
          <p
            class="mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide"
          >
            Consultations per day
          </p>
          <div class="">
            <div class="w-full">
              <div class="max-w-auto max-h-auto object-contain p-4">
                <BarChart
                  class="max-w-auto max-h-auto"
                  :chart-data="datacollection"
                  :display-x-label-string="false"
                  :step-size="5"
                />
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </narrow-layout>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import BarChart from '@/UI/charts/BarChart.vue';
import { AppointmentApi, VetStatsApi } from '@/api/index';
import StatisticsTimeRow from '@/components/StatisticsTimeRow';
import StatisticsSlotRow from '@/components/StatisticsSlotRow';
import { DE_COUNTRY_ID } from '@/config/countries';
import { mapState } from 'vuex';
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  differenceInDays,
} from 'date-fns';

export default {
  components: {
    BarChart,
    DateRangePicker,
    StatisticsTimeRow,
    StatisticsSlotRow,
  },
  data() {
    return {
      format,
      loading: true,
      selectedDate: format(new Date(), 'yyyy-MM'),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      datacollection: { labels: [], datasets: [] },
      payload: null,
      dateRange: {
        startDate: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
        endDate: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
      },
      hourData: {
        inHour: {
          hours: null,
          minutes: null,
        },
        evening: {
          hours: null,
          minutes: null,
        },
        weekend: {
          hours: null,
          minutes: null,
        },
        holiday: {
          hours: null,
          minutes: null,
        },
        total: {
          hours: null,
          minutes: null,
        },
      },

      workDayStart: null,
      workDayEnd: null,

      slotData: {
        appointmentCount: null,
        overtimePrescription: null,
        overtimeAppointment: null,
        ordinaryPrescription: null,
        ordinaryAppointment: null,
        finishedJournals: null,
        unfinishedJournals: null,
        averageRating: null,
      },
    };
  },
  computed: {
    ...mapState('user', ['user']),
    userIsGerman() {
      return this.user.country_id === DE_COUNTRY_ID;
    },
    overThreeMonths() {
      return (
        differenceInDays(new Date(this.toDate), new Date(this.fromDate)) > 90
      );
    },
    toDate() {
      return format(new Date(this.dateRange.endDate), 'yyyy-MM-dd');
    },
    fromDate() {
      return format(new Date(this.dateRange.startDate), 'yyyy-MM-dd');
    },
    customRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'This month': [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth() + 1, 0),
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      };
    },
  },
  mounted() {
    this.submit();
  },
  methods: {
    async submit() {
      if (this.overThreeMonths) return;

      this.loading = true;
      await this.getWorkedHours();
      await this.getWorkedSlots();
      this.loading = false;
    },
    async getWorkedSlots() {
      this.datacollection = { labels: [], datasets: [] };

      this.payload = {
        toDate: this.toDate,
        fromDate: this.fromDate,
      };
      try {
        const { data } = await AppointmentApi.getStatistics(this.payload);
        const {
          overtimePrescription,
          overtimeAppointment,
          ordinaryPrescription,
          ordinaryAppointment,
          finishedJournals,
          unfinishedJournals,
          averageRating,
          appointmentCount,
          chartData,
        } = data;

        this.slotData = {
          appointmentCount,
          overtimePrescription,
          overtimeAppointment,
          ordinaryPrescription,
          ordinaryAppointment,
          finishedJournals,
          unfinishedJournals,
          averageRating,
        };
        const parsedData = this.parseDataForChart(chartData);

        this.datacollection = {
          labels: parsedData.labels,
          datasets: [
            {
              label: 'Consultations',
              data: parsedData.dataset,
              backgroundColor: '#0061FF',
              borderColor: '#0061FF',
            },
          ],
        };
      } catch {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: 'Please try again or submit a support ticket',
        });
      }
    },
    async getWorkedHours() {
      const payload = {
        toDate: this.toDate,
        fromDate: this.fromDate,
      };
      try {
        const { data } = await VetStatsApi.getStatsHourly(
          payload.toDate,
          payload.fromDate
        );

        const {
          inHour,
          evening,
          weekend,
          holiday,
          total,
          workDayStart,
          workDayEnd,
        } = data;

        this.hourData = {
          inHour,
          evening,
          weekend,
          holiday,
          total,
        };

        this.workDayStart = workDayStart;
        this.workDayEnd = workDayEnd;
      } catch {
        this.$notify({
          group: 'error',
          title: 'Something went wrong',
          text: 'Please try again or submit a support ticket',
        });
      }
    },
    parseDataForChart(dataObject) {
      const labels = [];
      const dataset = [];
      Object.keys(dataObject).forEach(point => {
        labels.push(point);
        dataset.push(dataObject[point]);
      });
      return { labels, dataset };
    },
  },
};
</script>

<style lang="postcss" scoped>
>>> .calendars {
  flex-wrap: initial;
}

>>> .ranges {
  min-width: 90px;
}
</style>
