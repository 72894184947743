<script>
import { Bar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Bar,

  mixins: [reactiveProp],

  props: {
    chartData: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          position: 'nearest',
          mode: 'index',
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Date',
              },
              ticks: {
                stepSize: 10,
                min: 0,
                autoSkip: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Amount',
              },
              ticks: {
                min: 0,
                precision: 0,
              },
            },
          ],
        },
      },
    };
  },

  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>
