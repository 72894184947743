var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "narrow-layout",
    { staticClass: "min-h-screen" },
    [
      _c("spinner-overlay", {
        attrs: { loading: _vm.loading, color: "transparent" },
      }),
      _c("div", { staticClass: "relative flex flex-col" }, [
        _c("h1", { staticClass: "mb-6 font-display text-4xl" }, [
          _vm._v(
            " Statistics from " +
              _vm._s(_vm.fromDate) +
              " to " +
              _vm._s(_vm.toDate) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex w-1/2 space-x-4" },
          [
            _c("date-range-picker", {
              ref: "picker",
              attrs: {
                opens: "right",
                "locale-data": { firstDay: 1, format: "YYYY-MM-DD" },
                "max-date": new Date(),
                "single-date-picker": "range",
                "show-week-numbers": true,
                "show-dropdowns": true,
                ranges: _vm.customRanges,
                "auto-apply": true,
              },
              on: {
                update: function ($event) {
                  return _vm.submit()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "input",
                  fn: function (picker) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.format(new Date(picker.startDate), "yyyy-MM-dd")
                          ) +
                          " - " +
                          _vm._s(
                            _vm.format(new Date(picker.endDate), "yyyy-MM-dd")
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.dateRange,
                callback: function ($$v) {
                  _vm.dateRange = $$v
                },
                expression: "dateRange",
              },
            }),
          ],
          1
        ),
        _vm.overThreeMonths
          ? _c(
              "div",
              {
                staticClass:
                  "mb-3 mt-2 flex w-1/3 items-center space-x-3 rounded border border-red-200 bg-red-100 p-4 text-red-900",
              },
              [
                _c("span", [_c("fv-icon", { attrs: { icon: "warning" } })], 1),
                _c("span", [
                  _vm._v("Select a date range less than three months"),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass: "flex flex-col space-y-4 py-5",
          class: { "blur-sm filter": _vm.loading },
        },
        [
          !_vm.userIsGerman
            ? _c(
                "div",
                { staticClass: "flex md:pr-4" },
                [
                  _c("card", { staticClass: "w-full md:w-1/2" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide",
                      },
                      [_vm._v(" Work day ")]
                    ),
                    _c("div", { staticClass: "flex flex-col space-y-6 py-2" }, [
                      _c("div", { staticClass: "flex flex-col space-y-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.workDayStart) +
                            " - " +
                            _vm._s(_vm.workDayEnd) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex space-x-4" },
            [
              !_vm.userIsGerman
                ? _c("card", { staticClass: "flex-1" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide",
                      },
                      [_vm._v(" Worked hours ")]
                    ),
                    _c("div", { staticClass: "flex flex-col space-y-6 py-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-col space-y-2" },
                        _vm._l(_vm.hourData, function (item, index) {
                          return _c("statistics-time-row", {
                            key: index,
                            attrs: {
                              data: item,
                              title: index === "inHour" ? "Weekday" : index,
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("card", { staticClass: "flex-1" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide",
                  },
                  [_vm._v(" By the numbers ")]
                ),
                _c("div", { staticClass: "flex flex-col space-y-6 py-2" }, [
                  _c("div", { staticClass: "flex flex-col space-y-2" }, [
                    _c(
                      "div",
                      { staticClass: "inline-flex items-baseline space-x-1" },
                      [
                        _c("p", { staticClass: "text-xl font-semibold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.slotData.averageRating) + " "
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "block font-normal text-gray-800" },
                          [_vm._v(" in average rating ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "inline-flex items-baseline space-x-1" },
                      [
                        _c("p", { staticClass: "text-xl font-semibold" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.slotData.finishedJournals) +
                              "/" +
                              _vm._s(_vm.slotData.appointmentCount) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "block font-normal text-gray-800" },
                          [_vm._v(" finished journals ")]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "inline-flex items-baseline space-x-1" },
                      [
                        _c("p", { staticClass: "text-xl font-semibold" }, [
                          _vm._v(
                            " " + _vm._s(_vm.slotData.appointmentCount) + " "
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "block font-normal text-gray-800" },
                          [_vm._v(" total consultations ")]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex space-x-4" },
            [
              _c("card", { staticClass: "flex-1" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide",
                  },
                  [_vm._v(" Consultations (other services) ")]
                ),
                _c("div", { staticClass: "flex flex-col space-y-6 py-2" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col space-y-2" },
                    [
                      _c("statistics-slot-row", {
                        attrs: {
                          data: _vm.slotData.ordinaryAppointment,
                          title: "weekday",
                        },
                      }),
                      _c("statistics-slot-row", {
                        attrs: {
                          data: _vm.slotData.overtimeAppointment,
                          title: "outside business hours",
                        },
                      }),
                      _c("statistics-slot-row", {
                        attrs: {
                          data:
                            _vm.slotData.ordinaryAppointment +
                            _vm.slotData.overtimeAppointment,
                          title: "total",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("card", { staticClass: "flex-1" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-2 flex items-center border-b pb-2 font-display text-2xl font-black tracking-wide",
                  },
                  [_vm._v(" Consultations (prescription service) ")]
                ),
                _c("div", { staticClass: "flex flex-col space-y-6 py-2" }, [
                  _c(
                    "div",
                    { staticClass: "flex flex-col space-y-2" },
                    [
                      _c("statistics-slot-row", {
                        attrs: {
                          data: _vm.slotData.ordinaryPrescription,
                          title: "weekday",
                        },
                      }),
                      _c("statistics-slot-row", {
                        attrs: {
                          data: _vm.slotData.overtimePrescription,
                          title: "outside business hours",
                        },
                      }),
                      _c("statistics-slot-row", {
                        attrs: {
                          data:
                            _vm.slotData.ordinaryPrescription +
                            _vm.slotData.overtimePrescription,
                          title: "total",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-1" },
            [
              _c(
                "card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slotData.appointmentCount,
                      expression: "slotData.appointmentCount",
                    },
                  ],
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-2 border-b pb-2 font-display text-2xl font-black tracking-wide",
                    },
                    [_vm._v(" Consultations per day ")]
                  ),
                  _c("div", {}, [
                    _c("div", { staticClass: "w-full" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "max-w-auto max-h-auto object-contain p-4",
                        },
                        [
                          _c("BarChart", {
                            staticClass: "max-w-auto max-h-auto",
                            attrs: {
                              "chart-data": _vm.datacollection,
                              "display-x-label-string": false,
                              "step-size": 5,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }