<template>
  <div class="inline-flex items-baseline space-x-1">
    <p class="text-xl font-semibold">{{ data }}</p>
    <span class="block font-normal text-gray-800"> {{ title }} </span>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Number || null],
      default: 0,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedTitle() {
      return this.title
        .split(/(?=[A-Z])/)
        .join(' ')
        .toLowerCase();
    },
  },
};
</script>
